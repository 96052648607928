<template>
  <div>
    <b-row>
      <b-col
        lg="12"
        xl="12"
        md="12"
      />
      <h1>Coming soon...</h1>
    </b-row>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>
